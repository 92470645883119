import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ServiceFactory } from 'src/services/factory/service-factory.service'; 
import * as moment from 'moment';
import htmlToPdfmake from "html-to-pdfmake"
import { finalize } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

pdfMake.vfs = pdfFonts.pdfMake.vfs; 
import { environment } from 'src/environments/environment';
import { ToWords } from 'to-words';
import { OtpPopupComponent } from 'src/app/agreement-existing-user/otp-popup/otp-popup.component';

@Component({
  selector: 'app-retail-dwn-agreement-popup',
  templateUrl: './retail-dwn-agreement-popup.component.html',
  styleUrls: ['./retail-dwn-agreement-popup.component.scss']
})
export class RetailDwnAgreementPopupComponent implements OnInit {
  onboard_category:string;
  userData:any 
  userDetails_For_Doc:any = {
    products:{}
  }; 
  reaminingInstallment
  remainingAmount
  remainingAmountWords
  userAge:any 
  tenure_extended
  cntInstallment:any=[]
 
  @ViewChild('dialogHtmlBodyForPDF') dialogHtmlBodyForPDF: ElementRef;
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D; 
allProductArr =[];
productShow_Length =1;
agreementHide:any=false; 
  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private dataFactory: DataFactoryService,
    private router:Router, 
    private serviceFactory: ServiceFactory,
    private elRef:ElementRef,
    private route :ActivatedRoute,
    public dialogRef: MatDialogRef<RetailDwnAgreementPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private agreementPopupData: any, 
  ) { 
    debugger
    this.onboard_category = agreementPopupData.onboard_category;
   }


  ngOnInit(): void {
    this.dataFactory.get_userData().subscribe(res => { 
      if(res.url){
        debugger
        this.serviceFactory.loadingStart("body","Please wait while loading...","");
        this.userData =res;
        if(res.currentStatusID!=4 && (this.onboard_category=='retail_new' || this.onboard_category=='retail_renewal')){
          this.initiateESign()  
        }else{
          this.fetchUserDetailsAgreement();
        }
        
        if(res.esignData && res.esignData.errormessage){
          this.serviceFactory.msgPop(res.esignData.errormessage ,'error');
        }
        
      } 
     })


  }


  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    }
  });

 

  
  sendOtp(){
    debugger 
    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/sendOnboardOtpForAgreement";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/sendOnboardOtpForAgreement_Dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/sendOnboardOtpForAgreement_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/sendOnboardOtpForAgreement_Dhanwaan_renewal";
    }else{
     }
     this.serviceFactory.loadingStart("body","Please wait while loading...","");
    this.commonService.postData(apiUrl,{
      leadNo:this.userData.leadID,
      ob_customer_id:this.userData.ob_customer_id,
    }).pipe( 
      finalize(() => { 
       this.serviceFactory.loadingStop("body","");
      })
    ).subscribe((res) => {
      debugger
      this.serviceFactory.notification(res.message,res.status);
      if(res.status){
         this.openOtpPopup(res)
      }else{
        
      }
   }) 
  }
  

  openOtpPopup(res){

this.userDetails_For_Doc['user_full_name'] = this.userDetails_For_Doc['userName'];
this.userDetails_For_Doc['email_id'] = this.userDetails_For_Doc['email'];
this.userDetails_For_Doc['phoneNo'] = this.userDetails_For_Doc['phone'] 

 
    
    let dialogRef = this.dialog.open(OtpPopupComponent,{
      autoFocus: false,
      disableClose: true,
      width: '800px',
      data:{ 
        res:res,
        leadId:this.userData.leadID,
        pdfHtml:this.dialogHtmlBodyForPDF,
        date:this.userDetails_For_Doc['eSignDate']?this.userDetails_For_Doc['eSignDate']:new Date(),
        user:this.userDetails_For_Doc,
        onboard_category:this.onboard_category
      }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
  }
  

  eSignPdfGen(){
    debugger
    let forThis = this;
    this.serviceFactory.loadingStart(".mat-dialog-container","Please wait while loading...","");
        
    let option = {
      "tableAutoSize":true
    }    
    var innerHTML = htmlToPdfmake(this.dialogHtmlBodyForPDF.nativeElement.innerHTML,option); 
     // Add page breaks to the PDFMake content
     innerHTML.forEach((element, index) => {
      if (element.style && element.style.indexOf('page-break') !== -1) {
        innerHTML[index] = { text: '', pageBreak: 'before' };
      }
    });
    
    var dd = {
      pageSize: 'A4',
      pageMargins: [ 40, 40, 40, 90 ], 
      content:[
       {text:"Investment Advisory Agreement", alignment: 'center',fontSize: 16, bold: true,margin: [0, 0, 0, 0]},
       { 
        canvas: [
         {
          type: 'line',
					x1: 0, y1: 0,
					x2: 100, y2: 0,
					lineWidth: 2,
          color: '#001e5a',
         },
       ],
       margin: [0, 0, 0, 40],
       alignment: 'center'
       },
       
    innerHTML,
      ],
      defaultStyle: {
        fontSize: 10,
        bold: false,
        lineHeight:1.2
      },

      footer: function(currentPage, pageCount) { 
        debugger
        // Remove footer on the last page
        if (currentPage === pageCount || currentPage === pageCount - 1) {
            return {};
        }
        else{
          return  [
            { image:forThis.dataFactory.signImg_of_maish,
             alignment: 'right',width:150,margin: [10, 10, 10, 10]},
            // {text:"Marathon Futurex, A-603 6th Floor, Mafatlal Mills Compound, N M Joshi Marg, Lower Parel East. Mumbai 400013", alignment: 'center',fontSize: 11, color: '#444',bold: false},
          ]
        } 
      }

    };

    const pdfDocGenerator = pdfMake.createPdf(dd);
    // pdfMake.createPdf(dd).download("agreement.pdf");
    // return
     
    pdfDocGenerator.getBlob(async (blob) => {
      debugger 
     var file = new File([blob], "RNR_eSigned_Agreement.pdf", {
      type: "application/pdf",
      });
       this.eSign5digital(file);
 });
 
    
}


async initiateESign() {
  debugger 
   
  let apiUrl ="";
  if(this.onboard_category=="retail_new"){
    apiUrl="onboarding/initiateESign";
  }else if(this.onboard_category=="dwn_new"){
    apiUrl="dwOnboarding/initiateESign_dhanwaan";
  }else if(this.onboard_category=="retail_renewal"){
    apiUrl="subscriptionRenewal/initiateESign_renewal";
  }else if(this.onboard_category=="dwn_renewal"){
    apiUrl="dwSubscriptionRenewal/initiateESign_dhanwaan_renewal";
  }else{
   }
   

   let get_client_ip = await this.commonService.getClientipInfo();

   this.commonService.postData(apiUrl,{
    leadId:this.userData.leadID,
    ob_customer_id:this.userData.ob_customer_id,
    client_ip:get_client_ip,
    portfolio_restriction:this.agreementPopupData?.portfolio_restriction
  }).pipe( 
 finalize(() => { 
   
 })
).subscribe((res) => {
  if(res.status){
    this.fetchUserDetailsAgreement();
  }
})
 
}

  async fetchUserDetailsAgreement() {
    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/getUserDetailsForServiceAgreement";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/getUserDetailsForServiceAgreement_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/getUserDetailsForServiceAgreement_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/getUserDetailsForServiceAgreement_dhanwaan_renewal";
    }else{
     }

  let get_client_ip = await this.commonService.getClientipInfo();
  
 
  
  this.commonService.postData(apiUrl,{
    leadId:this.userData.leadID,
    ob_customer_id:this.userData.ob_customer_id,
    client_ip:get_client_ip
  }).pipe( 
    finalize(() => { 
     this.serviceFactory.loadingStop("body","");
    })
   ).subscribe((res) => { 
    if(!res.status){
      this.agreementHide = res.message; 
      return
    }
    this.userDetails_For_Doc = res.data; 
   
    this.setInstallmentData(res.data.products);
    this.bindShowProduct(res.data.products.productId);
    let dob = new Date(res.data.dob);  
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    this.userAge = Math.abs(year - 1970);
    this.reaminingInstallment = (parseInt(this.userDetails_For_Doc.products.duration)*2)-1
    this.remainingAmount = (this.userDetails_For_Doc.products.price_half_year)*(parseInt(this.userDetails_For_Doc.products.duration)*2) - this.userDetails_For_Doc.products.price_half_year    
    this.remainingAmountWords = this.toWords.convert(this.remainingAmount, { currency: true });
    if(this.userDetails_For_Doc.tenure_extended == true) {
      // this.tenure_extended = "In addition to the existing tenure, you have been provided one month free subscription."
      this.tenure_extended = " + 3 months (extension of one and half month for every 6 months)" 
    }
    
  })
}

setInstallmentData(productData)
{
   var arrNumberName=[{id:1,name:'First'},{id:2,name:'Second'},{id:3,name:'Third'}, {id:4,name:'Fourth'},{id:5,name:'Fifth'},
          {id:6,name:'Sixth'},{id:7,name:'Seventh'},{id:8,name:'Eighth'},{id:9,name:'Ninth'},{id:10,name:'Tenth'}]
  var arrNumberName_1=[{id:1,name:'First'},{id:2,name:'First'},{id:3,name:'Second'},{id:4,name:'Second'},{id:5,name:'Third'},{id:6,name:'Third'},
          {id:7,name:'Fourth'},{id:8,name:'Fourth'},{id:9,name:'Fifth'},{id:10,name:'Fifth'},{id:11,name:'Sixth'},{id:12,name:'Sixth'},{id:13,name:'Seventh'},
          {id:14,name:'Seventh'},{id:15,name:'Eighth'},{id:16,name:'Eighth'},{id:17,name:'Ninth'},{id:18,name:'Ninth'},{id:19,name:'Tenth'},{id:20,name:'Tenth'}]
  var tenureText='';
  if(productData.duration==5)
  {      
    if (productData['informed_investor_subscribed']==true)
    {
      for (let i = 0; i < 20; i++) {
        tenureText=arrNumberName_1.filter(d=>d.id==(i+1))[0].name +' 6 Months from the date of subscription';       
        if (i % 2 == 0)
        {
          this.cntInstallment.push({"productName":productData['productName'],"priceHalfYear":productData['price_half_year'],"tenureText":tenureText})
        }else{
         // this.cntInstallment.push({"productName":'Informed InvestoRR',"priceHalfYear":productData['informed_investor_amount'],"tenureText":tenureText})
        }
      }
    }
    else{ 
      for (let i = 0; i < 10; i++) {
        tenureText=arrNumberName.filter(d=>d.id==(i+1))[0].name +' 6 Months from the date of subscription';  
        if (i % 2 == 0)
        {
          this.cntInstallment.push({"productName":productData['productName'],"priceHalfYear":productData['price_half_year'],"tenureText":tenureText})
        }else{
          this.cntInstallment.push({"productName":productData['productName'],"priceHalfYear":productData['price_half_year'],"tenureText":tenureText})
        }        
      }  
     }          
  }
  else{
    if (productData['informed_investor_subscribed']==true)
    {        
      for (let i = 0; i < 4; i++) {
        tenureText=arrNumberName_1.filter(d=>d.id==(i+1))[0].name +' 6 Months from the date of subscription';
        if (i % 2 == 0)
        {
          this.cntInstallment.push({"productName":productData['productName'],"priceHalfYear":productData['price_half_year'],"tenureText":tenureText})
        }else{
         // this.cntInstallment.push({"productName":'Informed InvestoRR',"priceHalfYear":productData['informed_investor_amount'],"tenureText":tenureText})
        }
      }
    }
    else{ 
      for (let i = 0; i < 2; i++) {
        tenureText=arrNumberName.filter(d=>d.id==(i+1))[0].name +' 6 Months from the date of subscription';
        if (i % 2 == 0)
        {
          this.cntInstallment.push({"productName":productData['productName'],"priceHalfYear":productData['price_half_year'],"tenureText":tenureText})
        }else{
          this.cntInstallment.push({"productName":productData['productName'],"priceHalfYear":productData['price_half_year'],"tenureText":tenureText})
        }        
      }  
     }
  } 
}


  async eSign5digital(fileList) {
  debugger   
  
 let onboardType={
  "retail_new":'',
  "retail_renewal":'renewal/',
  "dwn_new":'dwn/',
  "dwn_renewal":'dwn-renewal/'  
 }
  
  let responseUrl = environment.hostName+onboardType[this.onboard_category]+"?uid="+this.userData.url; 

  var payload_upload = new FormData(); 
  payload_upload.append('file', fileList); 
  payload_upload.append('user', JSON.stringify(this.userData));
  payload_upload.append('responseUrl', responseUrl); 
  payload_upload.append('fullName', this.userDetails_For_Doc.userName); 
  

  this.commonService.postData('eSign/uploadDocument',payload_upload).subscribe((res)=> {
    debugger
     if(res.status){
      window.location.href =  res.data.url;
      } 
    }); 
}
 
 


  public blobToFile = (theBlob: Blob, fileName:string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
}

  removeGST(val){
    var get_GST = (Number(val) * 100)/118;  
    return get_GST; 
  }
  getGST(val){
     var get_GST = (Number(val) * 18)/100;
     return get_GST
  }

  // var canvas = document.getElementById("myCanvas");
  // var ctx = canvas.getContext("2d");
  // var image = new Image();
  // image.onload = function() {
  //   ctx.drawImage(image, 0, 0);
  // };
  // image.src = "";

   // render stamp image in canvas

// canvas = document.getElementById('canvas') as HTMLCanvasElement;




// render stamp image in canvas end

  getBase64ImageFromURL(url) { 
    return new Promise((resolve, reject) => { 
      var img = new Image(); 
      img.setAttribute("crossOrigin", "anonymous"); 
      img.onload = () => { 
        var canvas = document.createElement("canvas"); 
        canvas.width = img.width; 
        canvas.height = img.height; 
        var ctx = canvas.getContext("2d"); 
        ctx.drawImage(img, 0, 0); 
        var dataURL = canvas.toDataURL("image/png"); resolve(dataURL); 
      }; 
      img.onerror = error => { 
        reject(error); 
      }; 
      img.src = url; 
    }); 
  }



  bindShowProduct(elm){
    debugger

    if(typeof elm ==='string'){
      this.allProductArr = elm.split(',').map(Number);

     }else if(typeof elm ==='number'){
      this.allProductArr = elm.toString().split(',').map(Number);

    }else if(typeof elm ==='object'){
      this.allProductArr = elm.map(Number);

    }else{
     }

     if(this.allProductArr.length>1)
     this.productShow_Length=2
     }

 
     bindExpDate(){ 
        
      if(this.userDetails_For_Doc.tenure_extended == true){
        return  moment(this.userDetails_For_Doc['eSignDate']).add(15, 'months');
      }else{
        return  moment(this.userDetails_For_Doc['eSignDate']).add(12, 'months');
      }

     }
   

}
