// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dwLTAgreement: 'https://tracker.researchandranking.com/api/dwLTAgreement/',
  ccavenueApi: 'https://tracker.researchandranking.com/api/ccavenue/',
  baseUrl: 'https://stagingtracker.researchandranking.com/api/',
  baseUrlForUserAgreement: 'https://tracker.researchandranking.com/api/existingUserAgreement/',
  hostName: "https://onboard.equentis.com/",
  rnrCdn: "https://cdn.researchandranking.com/",
  aadhaar: {
    api: "https://alphabet.researchandranking.com/",
    redirect: "https://prod.aadhaarbridge.com/api/_form/",
    saCode: "EQUE3645",
    salt: "2jtf2mdwDh9Z",
  },
  ccavenue: {
    access_code: '', //staging
    working_key: '', //staging
    sandbox: false,
    merchant_id: '',
  },
  razerPay: {
    url: 'https://tracker.researchandranking.com/api/',
    key: "rzp_live_vbV0ELwC8aOKcT"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
