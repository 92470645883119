
       
<div class="text-center">
    <h5 style="text-align: center;">CONSENT BY CLIENT</h5>
</div>
        <p>I /We <span class="client_name"><strong>{{userDetails_For_Doc.base_detail['full_name']}}</strong></span>
            have read and understood the terms and conditions of Investment Advisory
            services provided by the <strong> <span>EQUENTIS WEALTH ADVISORY SERVICES LTD.</span></strong>
            along with the fee structure and mechanism for
            charging and payment of fee. Based on our request to the Investment Adviser, an opportunity
            was provided by the Investment Adviser to ask questions and interact with ‘person(s)
            associated with the investment advice.</p>
        <p>I have perused and understood the terms and conditions of the present Agreement
            and had ample opportunity to consult my counsels and advisors to understand and have
            accordingly sought advice</p>
    
        <p>I am entering in to the present Agreement for Investment Advisory Services out of my own
            free will and in sound mind. </p>
    
        <p>Confirmed by Me</p>
        <p><span class="client_name"><strong>{{userDetails_For_Doc.base_detail['full_name']}}</strong></span>
        </p>
    
        <p>&nbsp;</p>
        <p><strong>DECLARATION BY </strong><strong>EQUENTIS WEALTH ADVISORY SERVICES LTD.</strong></p>
        <ol style=" list-style: none; ">
            <li style="margin-bottom: 15px;">The Company hereby declares that it shall neither render any investment advice
                nor charge any fee until the client has signed this agreement. </li>
            <li style="margin-bottom: 15px;">Investment Adviser shall not manage funds and securities on behalf of the
                client and that it shall only receive such sums of monies from the client as are necessary to discharge the
                client’s liability towards fees owed to the Investment Adviser. </li>
            <li style="margin-bottom: 15px;">Investment Adviser hereby declares that it does not promise any assured or
                minimum returns or target returns.</li>
            <li style="margin-bottom: 15px;">The Company Further confirms that investments in the securities markets are
                subject to market risks, to be solely borne by the Client.</li>
        </ol>
        <p>&nbsp;</p>
        <div style="display: flex; justify-content: space-between;">
            <div style="text-align: left; ">
                <strong>UNDERSTOOD BY ME/US.<br><span id="spnFullname">{{userDetails_For_Doc.base_detail['full_name']}}</span>
                </strong>
    
            </div>
            <div style="text-align: right; margin-bottom: 15px;">
                <!-- <strong>{{userDetails_For_Doc.base_detail['counsellor_name']}} ({{userDetails_For_Doc.base_detail['counsellor_designation']}})</strong> -->
                <strong>Manish Goel</strong>
                <p>[For, Equentis Wealth Advisory Services Ltd.]</p>
            </div>
        </div>
        <p>&nbsp;</p>
        <!-- ==================================== -->
        <table class="table" border="1" cellpadding="10" style="word-break: break-all; width: 100%;">        
            <tr>
                <td>Fees specified under Investment Adviser Regulations and relevant circulars<br/> 
                    issued thereunder for Investment Advisory services.</td>
                <td>Rs. 1.25 Lakhs Annually
                    OR
                    2.5% of the Assets Under Advice</td>
            </tr>        
            <!-- <tr>
                <td>Fees charged to the client.</td>                    
                <td>
                    Rs. <strong>{{userDetails_For_Doc.base_detail['total_adv_nonadv_amount']}} /- ({{userDetails_For_Doc.base_detail['total_adv_nonadv_amount_in_words']}} only)</strong> exclusive of GST for 1 year for<br/> 
                    Investment Advisory Services (Date <span class="date_from"><strong>{{userDetails_For_Doc.base_detail['effective_date']}}</strong></span> to <span class="date_to"><strong>{{userDetails_For_Doc.base_detail['effective_date_plus_twoyear']}}</strong></span>)<br/>
                    Premium Membership Benefits: Free for the period (19 Years) <strong>{{userDetails_For_Doc.base_detail['eighteen_years_start_date']}}</strong> to <strong>{{userDetails_For_Doc.base_detail['effective_date_plus_twenty_year']}}</strong>.
                </td>
            </tr> -->

            <tr>
                <td>Fees charged to the client for IA Services for the 1st year</td>
                <td>
                     Rs. <b>{{userDetails_For_Doc.base_detail['first_year_adv_amount']}} /- ({{userDetails_For_Doc.base_detail['first_year_adv_amount_in_words']}} only)</b>
                    + GST<br />
                    Period (1 Year) <b>{{userDetails_For_Doc.base_detail['first_year_service_start_date']}}</b> to <b>{{userDetails_For_Doc.base_detail['first_year_service_end_date']}}</b>
                </td>
            </tr>

            <tr>
                <td>Fees charged to the client for IA Services for the 2nd year</td>
                <td>
                     Rs. <b>{{userDetails_For_Doc.base_detail['second_year_adv_amount']}} /- ({{userDetails_For_Doc.base_detail['second_year_adv_amount_in_words']}} only)</b>
                    + GST<br />
                    Period (1 Year) <b>{{userDetails_For_Doc.base_detail['second_year_service_start_date']}}</b> to <b>{{userDetails_For_Doc.base_detail['second_year_service_end_date']}}</b>
                </td>
            </tr>

            <tr *ngIf="userDetails_For_Doc.base_detail['tranches_totalnoofyears']==2">
                <td>Fees charged to the client for IA Services for the next 23 years</td>
                <td>
                    Rs. 998 + GST per year for 23 years from 3rd year onwards<br>
                    Period (23 Years) <b>{{userDetails_For_Doc.base_detail['third_year_service_start_date']}}</b> to <b>{{userDetails_For_Doc.base_detail['effective_date_plus_twenty_year']}}</b>
                </td>
            </tr>

            <ng-container *ngIf="userDetails_For_Doc.base_detail['tranches_totalnoofyears']==3"> 
                <tr>
                    <td>Fees charged to the client for IA Services for the 3rd year</td>
                    <td>
                         Rs. <b>{{userDetails_For_Doc.base_detail['third_year_adv_amount']}} /- ({{userDetails_For_Doc.base_detail['third_year_adv_amount_in_words']}} only)</b>
                        + GST<br />
                        Period (1 Year) <b>{{userDetails_For_Doc.base_detail['third_year_service_start_date']}}</b> to <b>{{userDetails_For_Doc.base_detail['third_year_service_end_date']}}</b>
                    </td>
                </tr>
        
                
                <tr>
                    <td>Fees charged to the client for IA Services for the next 22 years</td>
                    <td>
                        Rs. 998 + GST per year for 22 years from 3rd year onwards<br>
                        Period (22 Years) <b>{{userDetails_For_Doc.base_detail['fourth_year_service_start_date']}}</b> to <b>{{userDetails_For_Doc.base_detail['effective_date_plus_twenty_year']}}</b>
                    </td>
                </tr>
            </ng-container>
            
        </table>
     <br/>
        <h4 class="text-center mb-3">SUBSCRIPTION SERVICES AND PREMIUM MEMBERSHIP AGREEMENT</h4>
        <p>This <strong>SUBSCRIPTION SERVICES AGREEMENT ("Agreement")</strong> is made and entered into at on this
            <strong><span class="accepted_date"><strong id="streffective">{{userDetails_For_Doc.base_detail['effective_date']}}</strong></span>("Effective
                Date"):</strong>
        </p>
        <p class="mt-4 mb-4"><strong>BY AND BETWEEN:</strong></p>
        <p><strong>Equentis Wealth Advisory Services Limited</strong>, a company incorporated
            in india under the Companies Act, 2013 and having its registered office at A-603,
            Marathon Futurex, N.M Joshi Marg, Lower Parel, Mumbai - 400013. (Hereinafter referred to
            as the <strong>"Company" or "Equentis",</strong> which expression shall, unless repugnant to
            the context or meaning thereof, be deemed to mean and include its successors and
            assigns) of the <strong>ONE PART.</strong></p>
        <p><strong>AND</strong></p>
        
        <p id="individual" *ngIf="userDetails_For_Doc.base_detail.user_type=='individual'">
            <strong id="strfullname_2">{{userDetails_For_Doc.base_detail['full_name']}}</strong>, an adult Indian Inhabitant, aged about <strong id="strage">{{userDetails_For_Doc.base_detail['age']}}</strong> years, bearing PAN <strong>{{userDetails_For_Doc.base_detail['pancard']}}</strong>, residing at <strong id="strplaceadd">{{userDetails_For_Doc.base_detail['place']}}, {{userDetails_For_Doc.base_detail['address']}}</strong> (hereinafter referred to as the “Client”, which expression shall, unless repugnant to the context or meaning thereof, be deemed to mean and include its successors, legal heirs, administrators, executors, liquidators and permitted assigns) of the OTHER PART;
        </p>           
        <p id="corporate" *ngIf="userDetails_For_Doc.base_detail.user_type=='coorporate'">
            <strong>{{userDetails_For_Doc.base_detail['full_name']}}</strong>, a [body corporate incorporated under the laws of <strong>{{userDetails_For_Doc.base_detail['country']}}</strong> / individual / HUF/ Partnership Firm/ Proprietorship Firm] and having its registered office/residence at <strong>{{userDetails_For_Doc.base_detail['place']}}, {{userDetails_For_Doc.base_detail['address']}}</strong> Having PAN <strong>{{userDetails_For_Doc.base_detail['pancard']}}</strong>, [(hereinafter referred to as the “Client”, which expression shall, unless repugnant to the context or meaning thereof, be deemed to mean and include its successors and permitted assigns) of the OTHER PART; 
        </p>           
        <p class="mt-4">The Company and Client are hereinafter jointly referred to as the
            <strong>"Parties"</strong> and individually as a <strong>"Party"</strong>.
        </p>
        <p>WHEREAS The Company is inter alia is a SEBI registered Investment Advisor (Registration No. INA000003874) engaged
            in the business of providing investment advice under its brand Research and Ranking through its online portal
            having website <a href="https://researchandranking.com/" target="_blank">www.researchandranking.com</a>;</p>
      
        <p>WHEREAS The client has already subscribed the <span class="already_subs_product">“<strong>{{userDetails_For_Doc.base_detail['existing_product']}}</strong>”</span>
            Product of the Company through Investment Advisory Agreement dated <span class="prod_subs_date"><strong>{{userDetails_For_Doc.base_detail['master_agreement_date']}}</strong></span>
            and is fully satisfied with the services of the Company.
        </p>           
        <p><strong>WHEREAS in the interest of the Investors/Clients, Company has proposed a scheme for Membership Program for a period of 25 years.</strong></p>        
        <p>NOW THEREFORE THIS AGREEMENT WITNESSETH AND IT IS HEREBY AGREED AS UNDER: </p>
        <p><strong>1. Definitions :</strong></p>
        <p>
            1.1. Investment Advisory Services shall mean and include subscription of Investment Advisory Services of the
            products “Mispriced Opportunities, 5in5 strategy, Access to Secrrets,”
        </p>
         
        <p>
            1.2. Premium Membership benefits shall mean and include the Membership benefits as listed in Annexure A.         
        </p>
        <p><strong>2. TERMS OF AGREEMENT :</strong></p>
        <p>
            2.1. The parties herein agree, confirm and declare that the present Agreement shall be read as supplementary
            Agreement to the Investment Advisory Agreement dated<br/>
            <span class="agreement_sent_date"><strong>{{userDetails_For_Doc.base_detail['master_agreement_date']}}</strong></span>
            (Hereinafter referred to as Master Service Agreement) signed and executed between the parties herein.
        </p>
        <p>
            2.2. Parties agree that the relationship between the parties shall be governed by the Master Service Agreement
            read with present Agreement for Premium Membership program.        
        </p>
        <p>
            2.3. Parties Agree that by upon signing and execution hereof and upon the complete payment of fees as per the
            Terms of Payment/fee Schedule mentioned in Clause 5 herein, the term of the Master Service Agreement dated <span class="agreement_sent_date"><strong>{{userDetails_For_Doc.base_detail['master_agreement_date']}}</strong></span> is extended upto <span class="plus_20_year"><strong>{{userDetails_For_Doc.base_detail['effective_date_plus_twenty_year']}}</strong></span>
        </p>
        <p>
            2.4. The Client has subscribed to “<strong>{{userDetails_For_Doc.base_detail['existing_product']}}</strong>” through the said Master Service Agreement dated <span
                class="agreement_sent_date"><strong>{{userDetails_For_Doc.base_detail['master_agreement_date']}}</strong></span>.
        </p>
        <p>
            2.5. Equentis offers and Client hereby accepts the following services for a period of 25 years as follows :
        </p>
        <p>
            2.5.1. Mispriced Opportunities
        </p>
        <p>
            2.5.2. 5in5 Strategy
        </p>
        <p>
            2.5.3. Access to Secrrets
        </p>
        <!-- <p>
            2.5.4. Additional Opportunities        
        </p> -->
        <p>
            2.6 Equentis offers and Client hereby accepts the Membership Program. Client agrees to subscribe to the
            Membership Program and avail benefits thereto.
        </p>
       
        <br>
        <p><strong>3. SCOPE OF SERVICES :</strong></p>        
        <p>
            3.1 The Scope of Services for the Premium Membership Program is more particularly described in Annexure A.
        </p>
        <p>
            3.2. The Scope of Services for the products Mispriced Opportunities / 5in5 Strategy and Access to Secrrets are
            more particularly described in Annexure B.
        </p>
        <p>
            3.3. The Risk factors associated with the investments in market are more particularly described in Annexure C.
            The client agrees and understands the same.
        </p>
        <br>
        <p><strong>4. PERIOD OF SERVICE AND PREMIUM BENEFIT :
            </strong></p>        
        <p>
            4.1. The services under the present Agreement i.e. for Investment Advisory Services and Membership Benefits
            shall be provided for a period of 25 year from the date of execution hereof OR on the payment of first instalment
            as per Clause 5.
        </p>
        <p>
            *Subject to payments of all instalments mentioned in schedule under Clause 5.2
        </p>
         
        <br>
        <p>
            <strong>5. Fees:</strong> The parties understand and agree that the fees under the present agreement are paid/agreed to be paid
            for the  Investment Advisory Services for a period of 25 years as more particularly
            described hereinafter.
        </p>
        <p>
            5.1. The Fees for the Investment Advisory Services shall be as follows :
        </p>            
        <p>Subscription Fees already paid</p>            
        <table class="table" border="1" cellpadding="10" width="100%">
            <thead>
                <tr>
                    <th>Product </th>
                    <th>Fees (excluding GST)</th>        
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>5in5 strategy</td>
                    <td>
                        <ng-container *ngIf="productIds=='1'">
                             Rs. {{(userDetails_For_Doc.base_detail.adv_adjust_amount) | number:'1.0-1'}}
                        </ng-container>
                        
                        <ng-container *ngIf="productIds!='1'">
                         <span>N/A</span>
                        </ng-container>
                        
                    </td>
                </tr>
                <tr>
                    <td>Mispriced Opportunities</td> 
                    <td>
                        <ng-container *ngIf="productIds=='12'">
                            Rs. {{(userDetails_For_Doc.base_detail.adv_adjust_amount) | number:'1.0-1'}}
                        </ng-container>
                       
                        <ng-container *ngIf="productIds!='12'">
                        <span>N/A</span>
                       </ng-container>
                    </td>
                </tr>        
                <tr>
                    <td>Combo (5in5 strategy + Mispriced Opportunities)</td>
                    <td>
                        <ng-container *ngIf="productIds=='1,12'">
                            Rs. {{(userDetails_For_Doc.base_detail.adv_adjust_amount) | number:'1.0-1'}}
                        </ng-container>
                       
                        <ng-container *ngIf="productIds!='1,12'">
                        <span>N/A</span>
                       </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td>Rs. {{(userDetails_For_Doc.base_detail.adv_adjust_amount) | number:'1.0-1'}}</td>
                </tr>
            </tbody>
        </table>
      <br/>
            
     
             
        <p> 
            5.2. The client hereby agrees and accepts to make the payments for the Investment Advisory Services as per the following Schedule :
        </p> 
        <table class="table" border="1" cellpadding="10" width="100%">
            <thead>
                <tr>
                    <th width="80">Year</th>
                    <th width="80">Sr. No. </th>
                    <th>Date of Payment</th>                        
                    <th>Amount</th>
                    <th>GST</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                 <ng-container *ngFor="let tranch of userDetails_For_Doc.year_by_installment; let ind =index">
                  <tr *ngFor="let item of tranch.adv_tranch; let i=index">  
                    <td *ngIf="i==0" [attr.rowspan]="tranch.adv_tranch.length+1"><span [innerHTML]="ordinal_suffix_of(ind+1)"></span></td>   
                    <td>{{i+1}}</td>
                    <td>{{item.schedule_date}}</td>
                    <td>Rs. {{item.amount | number:'1.0-1'}}</td>
                    <td>Rs. {{item.gst | number:'1.0-1'}}</td>
                    <td>Rs. {{item.amount_with_gst | number:'1.0-1'}}</td>
                  </tr>
                  <tr  style=" font-weight: bold; ">           
                 
                    <td colspan="2">Total</td> 
                    <td>Rs. {{tranch.amount | number:'1.0-1'}}</td>
                    <td>Rs. {{tranch.gst | number:'1.0-1'}}</td>
                    <td>Rs. {{tranch.amount_with_gst | number:'1.0-1'}}</td>
                  </tr>

                </ng-container>
                  <tr style=" font-weight: bold; ">
                      <td colspan="3">Total Advisory Fees for {{userDetails_For_Doc.year_by_installment.length}} Years</td>
                      <td>Rs. {{userDetails_For_Doc.base_detail['total_amount_to_be_paid'] | number:'1.0-1'}}</td>
                      <td>Rs. {{userDetails_For_Doc.base_detail['total_amount_to_be_paid_gst'] | number:'1.0-1'}}</td>
                      <td>Rs. {{userDetails_For_Doc.base_detail['total_amount_to_be_paid_with_gst'] | number:'1.0-1'}}</td>
                  </tr>
            </tbody>
        </table>
        <br/>
        <p>
            5.3. The client hereby agrees and accepts to make the payments for the Investment Advisory Services from third year onwards as per the following Schedule :       
        </p>
        <table class="table" border="1" cellpadding="10" width="100%">
            <thead>
                <tr>
                    <th style="width: 80px;">Sr. No.</th>
                    <th>Date of Payment</th> 
                    <th>Amount</th> 
                </tr>
            </thead>
            <tbody class="membership_payment_dates">
                  <tr>
                    <td>1</td> 
                    <td>Every 6 months from the last due date of payment</td>
                    <td>499</td> 
                  </tr> 
                  <tr>
                    <td>2</td> 
                    <td>Every 6 months from the last due date of payment</td>
                    <td>499</td> 
                  </tr>  
                  <tr>
                    <td colspan="2" *ngIf="userDetails_For_Doc.base_detail['tranches_totalnoofyears']==2">
                        Total Advisory Fees of 1 year from third year onwards for 23 Years
                    </td> 
                    <td colspan="2" *ngIf="userDetails_For_Doc.base_detail['tranches_totalnoofyears']==3">
                        Total Advisory Fees of 1 year from third year onwards for 22 Years
                    </td>  
                    <td>998</td>
                  </tr>                                                                
            </tbody>
        </table>
        <br>
        <p><strong>6. APPOINTMENT OF NOMINEE :</strong></p>            
        <p>
            6.1. The Client shall be entitled to appoint the Nominee of the Client who shall be entitled to receive
            Investment advisory services under the - Premium Membership in the event of death or disability of the Client 
            or in case if Client wishes to assign the rights to nominee, the nominee can take over the services;
  
        </p>
        <p>
            6.2. Client is entitled to change the Nominee appointed under the present Agreement with a prior written
            intimation to Equentis and shall execute all such necessary documents as may be required to change the Nominee.
        </p>
        <p>
            6.3. Client understands that the Nominee so appointed shall not be entitled to further appoint any Nominee or
            assign the rights under the present Agreement to any person.
        </p>
    
        <p><strong>7. OBLIGATIONS OF NOMINEE : </strong> The Nominee appointed by the client shall be entitled to receive the investment advisory services from company, in any of the above mentioned events, Provided that :
        </p>
        <p>
            7.1. Such Nominee shall promptly inform the company about the death or disability of the Client with proper
            documentary proof and shall complete the on boarding process, including KYC and complete the “risk profiling”
            and “suitability assessment” with the Investment Advisor within a period of 30 days after the death or
            disability of the client.
        </p>
        <p>
            7.2. Such Nominee shall promptly execute all such documents including the Service Agreement or any such document
            as may be necessary to enable the Investment Advisor to provide the services under the present Agreement to
            him/her within a period of 30 days after the death or disability of the client.
        </p>
        <p>
            7.3. Such Nominee shall be bound by the terms of this Agreement and Master Service Agreement.
        </p>
        <p>
            7.4. Investment Advisory services to the Nominee shall be provided as per the risk assessment and suitability
            assessment of the Nominee and may differ from the Investment Advice provided to the Client herein.
        </p>
        <p>
            7.5. If by virtue of any change in regulations or law, if the Master Service Agreement is required to be
            amended, then such Nominee shall promptly sign and execute such agreement as may be necessary.
        </p>
    
        <p><strong>8. ASSIGNMENT :</strong> </p>
        <p>8.1 The Client shall not be entitled to assign any of the Client’s rights, obligations or benefits under this Agreement without the prior written consent of the Equentis/Company save and except the rights, obligations and/or benefits under this Agreement shall automatically stand transferred to the Nominee of the Client in case of events mentioned in Clause 6 herein.</p>        
        <p><strong>9. DEFAULT OF PAYMENT:</strong></p>
        <p>
            9.1. If the client fails to make the payment in the schedule provided hereinabove, the Company has the right to
            terminate the - Membership/Premium Membership benefits in accordance with Clause 11 of the Master Service Agreement.
        </p>
        <p>
            9.2. In the event of failure to make any payments in accordance with the Payment Plan as stated in Clause 5
            herein, Company shall provide a grace period of 10 days to the client for fulfilling the payment obligation;
            after the expiry of ten (10) days if the client fails to make any such prescribed payment, the full balance
            owing on client’s account shall become immediately due and payable, in compliance with SEBI (IA) Regulations,
            2013. After the expiry of 10 days, the overdue amount on client’s account shall attract an interest rate of 2%
            per month (24% per annum).        
        </p>
        <p>
            9.3. In the event the Client fails to pay the fees as per Schedule of Fees in Clause 5 hereinabove, Equentis
            shall provide the Investment Advisory Services and Premium Membership Benefits only for the period for which it has
            charged the service fees.
        </p>
        <p>
            9.4. The client shall be then free to manage his/her/its investments.
        </p>
        <p>
            9.5. The relationship between the parties shall then be governed as per the Clause 11 and 12 (Implication of
            Termination and Transition Obligations) of the Master Service Agreement.
        </p>
        <br>
        <p><strong>Escalation Matrix:</strong></p>
        <p>The client shall be eligible for quick resolution within 48 working hours of all queries related to
            recommendations given by the company via the privileged email address <a
                href="mailto:privilegedcustomer@researchandranking.com">privilegedcustomer@researchandranking.com</a>.
            In case of no response from the said email id within 48 hours, please contact at
            <a href="mailto:sanjeev.anand@researchandranking.com">sanjeev.anand@researchandranking.com</a> and
            <a href="mailto:manish@equentis.com">manish@equentis.com</a>.
        </p>
        <br>
        <p>In witness whereof, the Parties hereto have caused this Agreement to be executed on the date and at the place
            mentioned in the Schedule hereinabove in the manner as hereinafter appearing.</p>
        <br>
        <p>Signed and delivered by</p>
        <br>
        <br>
        <p><strong>Equentis Wealth Advisory Services Limited ) </strong></p>
        <br>
        <p>By the hand of <span class="couns_name"><strong>Mr. Manish Goel</strong></span>,
            its authorized signatory )</p>
        <br>
        <p>SIGNED AND DELIVERED )</p>
        <br>
        <br>
        <p>by the within named Client <span class="client_name"><strong>{{userDetails_For_Doc.base_detail['full_name']}}</strong></span>
            )</p>
    
        <p style="text-align: center;"><strong>Annexure A</strong></p>
        <p style="text-align: center;">MEMBERSHIP BENEFITS</p>
        <p><strong>THE MEMBER SHALL BE ENTITLED TO FOLLOWING BENEFITS:</strong></p>
                        
            <p>
               1. Elite Club Conclave: The client shall be eligible to attend all ‘elite club conclaves’ exclusively
                    reserved for premium members without any additional charges. The exclusive “elite club conclave” are
                    specifically designed business conclaves, investor education programs/conclaves conducted by Equentis on
                    quarterly basis for the premium members to empower the members to chart their own journey of wealth
                    creation.
            </p>        
            <p>
                2. Financial Planning: Specifically designated Financial Mentor shall assist the client for the Financial planning.
            </p>
            <p>3. Dedicated Privileged Customer Service Support: Client will be provided a dedicated desk for resolution of queries.</p>
            <p>
              4. "Call with Founder": Get exclusive opportunity to interact with the Founders of Equentis to seek and
                    explore investment opportunities in every quarter of the year.
            </p>
            <p>
              5. The client shall be eligible for quick resolution within 48 working hours of all queries related to
                    recommendations given by the company via the privileged email address
                    “privilegedcustomer@researchandranking.com” / helpline.
            </p>  
            <p>
                6. Informed InvestoRR: The Client shall be eligible for having access to videos of Informed InvestoRR on a weekly basis.
             </p> 
             <p>
                7. Appoint Your Nominee: Premium Member can appoint a nominee who can continue to receive all the
                premium membership benefits for the remainder period of term of agreement.
            </p>     
             
        <p><br></p>        
        
        <p style="text-align: center;"><strong>Annexure B</strong></p>        
        <p><strong>SCOPE OF SERVICE FOR PRODUCT 5in5 STRATEGY</strong></p>
            
            <p>
                1. Equentis shall create and recommend a Model or Personalized/Customized Portfolio of 20-25 potential
                    multibagger stocks that suits the goals of client and their risk profile.
            </p>
            <p>
                2. Equentis shall provide detailed Research Reports to client along with portfolio allocation, buying
                    range and upside potential.
            </p>
            <p>
                3. Alerts via Email / Push Notifications for news / events shall be sent for every recommended stocks.
            </p>
            <p> 
                4. Dedicated Support via live chat, phone and emails from dashboard shall be provided to client.
            </p> 
            <p>
                5. Equentis shall provide a state of the art “Portfolio Tracker” to track the health of client’s
                    portfolio on the dashboard.
            </p>
            <p>
                6. Regular information on investing practices and market developments is shared with the client.
            </p>
            <p>
                7. Equentis shall provide Quarterly reports for the portfolio performance with the client. Based upon
                    quarterly reports – rebalancing of stocks for optimum performance and maximum output will be advised to
                    the Client.
            </p>
            <p>
                8. Financial planning involving analysis of clients’ current financial situation, identification of their
                    financial goals, and developing and recommending financial strategies to realize such goals any other
                    services incidental to the above.
            </p>        
             
        <p><strong>SCOPE OF SERVICE FOR PRODUCT MISPRICED OPPORTUNITIES</strong></p>
         
            <p>
                1. Equentis shall recommend One high growth stock every month. Stock which is trading lower than the stock’s intrinsic/average/generic value is selected.
            </p> 
            <p>
                2. Companies having a market cap of Rs. 2000 crore and above are selected.
            </p>
            <p> 
                3. To track significant events in the stock / industry, an update will be provided on a case to case basis.
            </p>
            <p>
                4. A complete solution is provided with research reports, portfolio allocation, upside potential,
                    downside risk and information on entry and exit price range with each stock recommendation while
                    investing in the share market.
            </p>
            <p>
                5. Fundamentally solid and high growth 10-12 stocks are selected for SIP that are trading lower than the
                    stock intrinsic value but have a high potential to grow in the foreseeable future.
            </p>
    
            <p>
                6. Stocks recommended to the client are periodically monitored and alerts via SMS, email updates are
                    shared on client’s dashboard for news / events affecting the recommended companies.
            </p>                     
         
        <p><strong>SCOPE OF SERVICE FOR PRODUCT ACCESS TO SECRRETS</strong></p>
        <p>Equentis shall provide advice to client to possible investment opportunities with unlisted and/or “about to be
            listed” companies, or companies whose IPO is in pipeline over 18-24 months.</p>        
        <br>     
        
        <p><strong>SCOPE OF SERVICE FOR INFORMED INVESTORR</strong></p>
        
            <p>1. Equentis shall provide a video with the market insights on a weekly basis.</p>
            <p>2. These videos would be of utmost significance to an investor as they would summarise all the relevant data and facts that one would need to make an investment decision.</p>
            <p>3. Information from media, business channels, financial papers will be sieved and shared with the client in a short video leading to saving his time and efforts of screening through the various sources to get the right information.</p>
            <p>4. Learnings from the Best Investment Gurus will be shared with the client to make him a more informed investor.</p>
   
       
        <p style="text-align: center;"><strong>Annexure C</strong></p>
        <p><strong>RISK FACTORS ASSOCIATED WITH INVESTMENT</strong></p>
        
            <p>
                1. The Client understand that the risk factors associated with Investments made in the securities markets
                    pursuant to the Advisory services provided by Equentis are more particularly described in the <strong>Clause 5 and Annexure C</strong> of the Master Service Agreement dated <span class="agreement_sent_date"><strong>{{userDetails_For_Doc.base_detail['master_agreement_date']}}</strong></span>.
            </p>
            <p>
                2. The Client understands that over and above the said risk factors, any investment in the unlisted
                    companies may carry with them higher risks in terms of options to liquidate such stocks.
            </p>
            <p>
                3. Securities which are not quoted/listed on the stock exchanges are inherently illiquid in nature and
                    carry a larger liquidity risk in comparison with securities that are listed on the exchanges. This may,
                    increase the risk of the portfolio. All efforts will be made, but no assurance can be given to ensure
                    smooth exit or sale of all unlisted shares, recommended by Equentis.
            </p>
            <p>
                4. Other risks: There are multiple other risks which are difficult to anticipate or quantify. These could
                    be changes in interest rates, currency movement, country risk, natural calamity, riots etc which may
                    impact the performance of the product. We will do whatever best possible to insulate our product
                    performance from such risks.
            </p>
    
      
        <p><strong>RISKS- INTERNAL /OPERATIONAL</strong></p>
        
            <p>
                1. Due to internal change in strategy or in compliance with new rules, the current product offerings may
                    undergo minor or material change in the future in its scope of services/pricing OR may be altogether
                    scrapped OR may be altogether scrapped and replaced with a new product/pricing. Such changes may not
                    result in complete satisfaction of all clients during all such instances.
            </p>
            <p>
                2. Equentis is required to maintain certain approvals or licenses required in the ordinary course of
                    business and the failure to obtain them in a timely manner or at all may adversely affect our
                    operations.
            </p>
            <p>
                3. Investment in securities markets and the Investment Advisory services are interlinked with continued
                    use of the internet, data connectivity, and the reliability and adequacy of online infrastructure in
                    India. Any disruption in this can affect backend operations which may have a material bearing on the
                    services provided by the Equentis.
            </p>
      
         
 