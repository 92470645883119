import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; 
import * as echarts from 'echarts';
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/services/api/common.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2'; 
import { RetailDwnAgreementPopupComponent } from 'src/app/agreement/retail-dwn-agreement-popup/retail-dwn-agreement-popup.component';

@Component({
  selector: 'app-portfolio-analysis',
  templateUrl: './portfolio-analysis.component.html',
  styleUrls: ['./portfolio-analysis.component.scss'],
  host: {'class': 'page-wrapper'} ,
})
export class PortfolioAnalysisComponent implements OnInit {
  onboard_category:string;
  userData:any
  storeAnalysisData:any = {} 
  max_score = 100;
  growth_analysis_graph
  agreementPopupRef;

  sutabilityB_retail:any={
    4:"Investment Objective",
    5:"Time Horizon",
    10:"Risk Appetite",
    16:"Investment Objective",
    17:"Time Horizon",
    22:"Risk Appetite",
   }

   religious_list:any = [
    {name:"No", value:"0", alert:null },
    {name:"Yes", value:"1", alert:"Alcohol, Recreational Cannabis, Banking & Insurance, Pork, Gambling, Tobacco, Movies, Music & Haraam Entertainment stocks will not be recommended to you."}
    // {name:"Jain-Compliant", value:"Jain-Compliant", alert:"Tobacco, Liquor, Animal Slaughter & Animal Product Usage stocks will not be recommended to you."},
    // {name:"Iskcon-Compliant", value:"Iskcon-Compliant", alert:"Tobacco, Caffeine, Liquor, Animal Slaughter, Animal Product Usage & Hotel Industry stocks will not be recommended to you."}
   ]

   activeReligious = this.religious_list[0];

  constructor(
    public dialog: MatDialog,
    private dataFactory: DataFactoryService,
    private serviceFactory: ServiceFactory,
    private commonService:CommonService ,
    private router:Router,
    private route :ActivatedRoute,
    ) { 
      this.onboard_category = route.parent.snapshot.data['roles'];

      if(this.onboard_category=="dwn_new" || this.onboard_category=="dwn_renewal"){
        this.sutabilityB_retail={
          8:"Investment Objective", 
          16:"Investment Objective",
          17:"Time Horizon",
          22:"Risk Appetite",
         }
      }


        this.dataFactory.get_userData().subscribe(res => { 
            if(res.url){
              debugger
              if(res.currentStatusID==4 && (this.onboard_category=="retail_new" || this.onboard_category=="retail_renewal")){
                this.agreementPopup();
              }else{
                this.userData =res; 
                this.getRiskProfileAnalysis();
              }

              
            } 
           })
    }

  ngOnInit(): void { 
    
    
  }
  submitForm(formData){
    debugger
    if(formData.invalid){
      return 
    }
     if(this.onboard_category=="retail_new" || this.onboard_category=="retail_renewal"){
      this.ShowAttention()
    }else if(this.onboard_category=="dwn_new" || this.onboard_category=="dwn_renewal"){
      this.initiateESign()
     }else{
     }

    
  }
 
  ShowAttention(){
    Swal.fire({ 
      title: 'Attention!',
        html: `<div style=" line-height: 26px; ">Please confirm that payment is being paid from subscriber's own account.</div>`,
     // icon: 'warning', 
      customClass: {
          confirmButton: 'mat-flat-button mat-button-base mat-primary bg-primary',
          cancelButton: 'mat-stroked-button mat-button-base ',
          container: 'modal-yes-no Modal_Delete', 
          actions: 'modal-btn-yes-no mb-4', 
          header: 'pt-4', 
      },
      width: '36em',
      showCloseButton: true,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel' , 
      focusConfirm:false, 
      focusCancel:true,     
     }).then((result) => {
        if (result.isConfirmed) {
          this.agreementPopup()
        }
      })
  }
 

  getRiskProfileAnalysis() {
    debugger 
     
    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/getRiskProfileDetails";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/getRiskProfileDetails_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/getRiskProfileDetails_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/getRiskProfileDetails_dhanwaan_renewal";
    }else{
     }

     

    this.commonService.postData(apiUrl,{
      leadId:this.userData.leadID,
      ob_customer_id:this.userData.ob_customer_id
    }).subscribe((res) => {
       debugger
       if(res.status){
           this.storeAnalysisData = res.data;
           this.growthAnalysis();
      
       }
        
        
    });
  }

  growthAnalysis() {
      let forThis = this;

      
  var tick_count = 0;
  var splitScore = [];

  if(this.onboard_category=="retail_new" || this.onboard_category=="retail_renewal"){
    this.max_score = 100;
    splitScore = [
      [0.65, '#3BD9C6'],
      [1, '#FDD835 '],
    ];
  }else{
    this.max_score = 100;
    splitScore = [
      [0.39, '#FDD835'],
      [1, '#FF7043 '],
    ];
   }

 
    var chartDom = document.getElementById('growthChart');
    var myChart = echarts.init(chartDom);
    this.growth_analysis_graph = echarts.init((document.getElementById('growthChart')) as any);

 

    let option = {
      series: [{
          type: 'gauge',
          radius: "100%", 
          //splitNumber: 2,
          axisLine: {
              lineStyle: {
                  width: 20,
                  color: splitScore,


                    /*if (rating <= 64) { /***  retail
                      setProfileType = 'low'
                    } else {
                      setProfileType = 'Moderate'
                     }*/

                    /*if (rating <= 38) { /***  DWN
                      setProfileType = 'Moderate'
                    } else {
                      setProfileType = 'High'
                     }*/

                    //[1, '#FF7043']
                  
              }
          },
          pointer: {
              itemStyle: {
                  color: 'auto'
              }
          },
          axisTick: {
              distance: -20,
              length: 8,
              lineStyle: {
                  color: '#fff',
                  width: 2
              }
          },
          splitLine: {
              distance: -20,
              length: 30,
              lineStyle: {
                  color: '#fff',
                  width: 4
              }
          },
          axisLabel: {
              show: false, 
          },
          detail: {
              valueAnimation: false,
              formatter: ' ',
              color: 'auto',
              fontSize: 20,
          },
          animationDuration: 10,
          data: [{
              value: 0,
              keyword: ''
          }]
      }]
  };
 


 
  var gauge_interval = setInterval(function () {
      tick_count ++;
      if (tick_count > 4) {
        
        
        option.series[0].data[0].value = forThis.storeAnalysisData.Score;
        option.series[0].detail.formatter = forThis.storeAnalysisData.ProfileType;
        myChart.setOption(option, true);

        clearInterval(gauge_interval)
        
      } else {
          if(tick_count%2==0) {
              option.series[0].data[0].value = (Math.random() * 48)
              option.series[0].detail.formatter = ' ';
              myChart.setOption(option, true);
          } else {
              option.series[0].data[0].value = (Math.random() * 51)+50;
              option.series[0].detail.formatter = ' ';
              myChart.setOption(option, true);
          }
      }
  }, 450);
    this.growth_analysis_graph.setOption(option, true);
  }

  agreementPopup(){
   this.agreementPopupRef = this.dialog.open(RetailDwnAgreementPopupComponent,{
        autoFocus: false,
        disableClose: true,
        width: '1000px', 
        data:{onboard_category:this.onboard_category,portfolio_restriction:this.activeReligious?.value},
        height:"92%"
    });

    this.agreementPopupRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

 
  reSubmitRisk(){
      debugger
      this.router.navigate(['../risk-profile'],{ 
        queryParams: {uid: this.userData.url},
        relativeTo: this.route 
      });
      this.dataFactory.set_data("reSubmitRisk",this.storeAnalysisData);
  }


  async initiateESign() {
    debugger 
    let get_client_ip = await this.commonService.getClientipInfo();
   
    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/initiateESign";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/initiateESign_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/initiateESign_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/initiateESign_dhanwaan_renewal";
    }else{
     }

     this.serviceFactory.loadingStart("body","Please wait while loading...","");

    this.commonService.postData(apiUrl,{
      leadId:this.userData.leadID,
      ob_customer_id:this.userData.ob_customer_id,
      client_ip:get_client_ip
    }).pipe( 
   finalize(() => { 
    this.serviceFactory.loadingStop("body","");
   })
 ).subscribe((res) => {
      debugger
     
        this.serviceFactory.notification(res.message,res.status);
        if(res.status){
          this.router.navigate(['../investment-details'],{ 
            queryParams: {uid: this.userData.url},
            relativeTo: this.route 
          });
      
        }
   });
  
  }
  
}
