

 <div class="simple_logo">
  <a href="javascript:;">
      <img src="./assets/images/equentis_logo.png" alt="LOGO">
  </a>

 

</div> 

<section class="section_investment">
 <div class="page_top_heading">
  <h2 class="page_title_2">Hello {{user_InvestmentDetails['customer_name']}}. Thank you for filling your Risk Profile.</h2>
  <h4 class="page_title_3">Please mention your investment details below.</h4>
</div>
  
  <div class="investment-widget"> 
      
      <div class="row_investment_amount mb-4" >
        <mat-checkbox (change)="onChangeAccepted($event)" [(ngModel)]="user_InvestmentDetails['investment_accepted']" class="mr-2"></mat-checkbox>
        I confirm that my min Investment Amount is Rs 
        <fieldset [disabled]="true" style=" display: flex; align-items: center; ">
          <mat-icon style="width: 18px;margin-left: 8px;" matPrefix svgIcon="currency-inr" style=" width: 16px; "></mat-icon> 
          <input style=" padding: 0 2px; background: none; width: 90px; font-weight: 600; " class="form-control border-0" [value]="user_InvestmentDetails['investment_amount']+' Lacs.*'">
        </fieldset>  
      </div>


      
    



       <form #salesform="ngForm" (ngSubmit)="ShowAttention(salesform)">


        
      <!-- (search)="onSearch($event,'industry')" -->
        
     


     
      <div class="dont_buy_row row pt-2">

        <div class="col-12 mb-4"> 
          <p>Do you want to personalize your portfolio based on a specific religious compliant?</p>
      

          <div class="religious_tab" >
            <div class="religious_tab_link" (click)="activeReligious = item" [class.active]="activeReligious==item" *ngFor="let item of religious_list">{{item.name}}</div> 
         </div>
    
         <div class="portfolio-error-wrapper" *ngIf="activeReligious.alert">
          {{activeReligious.alert}}
         </div> 
        </div>


        <ng-container *ngIf="onboard_category!='dwn_renewal'" >

        <div class="col-6" [ngModelGroup]="'industry'"> 
            <p>Industries you don't want in your portfolio</p>
            <div class="dont_buy_box mb-4" *ngFor="let item of [0,1]; let i = index" [ngModelGroup]="i">
                <div class="form-group"> 
                <label>Industry Name {{i+1}}</label>
                <ng-select 
                [items]="companyAndIndustryData['industry']" 
                bindLabel="industry_name" 
                bindValue="industry_id" 
                [clearSearchOnAdd]="false" 
                name="id" ngModel>
              </ng-select>
              </div>
              <div class="form-group"> 
                <label>Reason</label>
                  <textarea  class="form-control" name="reason" ngModel></textarea>
                </div>
            </div>

        </div>


        <div class="col-6" [ngModelGroup]="'company'"> 
            <p>Companies you don’t want in your portfolio</p>
            <div class="dont_buy_box mb-4" *ngFor="let item of [0,1]; let i = index" [ngModelGroup]="i">
              <div class="form-group"> 
              <label>Company Name {{i+1}}</label>
              <ng-select 
                [items]="companyAndIndustryData['company']" 
                bindLabel="company_name" 
                bindValue="fincode" 
                [clearSearchOnAdd]="false" 
                name="id" ngModel>
              </ng-select>
            </div>
            <div class="form-group"> 
              <label>Reason</label>
              <textarea  class="form-control" name="reason" ngModel></textarea>
              </div>
            </div>

        </div>

        </ng-container>
        
        
      </div>

 <hr>
   
 
 <ng-container *ngIf="onboard_category!='dwn_renewal'" >

<div class="row">
 

  <div class="form-group col-md-6"> 
    <label>No. of Stocks</label>
    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
         <mat-select name="no_of_stocks_preferred_range" [(ngModel)]="no_of_stocks_preferred_range" (selectionChange)="onChange_No_of_stocks($event)" >
          <mat-option value="20-25">20-25</mat-option>  
          <mat-option value="15-20">15-20</mat-option> 
          <!-- <mat-option value="12-15">12-15</mat-option> -->
        
      </mat-select>
    </mat-form-field> 
  </div>

  

  <div class="form-group col-md-6"> 
    <label>Comments</label>
    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
        <textarea matInput name="additional_comment" maxlength="250" ngModel></textarea>
    </mat-form-field> 
  </div>


</div>


<div class="dsclmr_msg mb-5" *ngIf="dsclmr_no_of_stocks_preferred_range || dsclmr_dont_want">
  <p class="mb-0" *ngIf="dsclmr_no_of_stocks_preferred_range"><strong>Disclaimer</strong> : Our preference regarding the number of stocks is 20-25. If you have requested for any number lower than that, we may not be able to match your portfolio performance with our model portfolio. Important to note that the number of stocks cannot be less than 15.</p>
  <p class="mb-0 mt-4" *ngIf="dsclmr_dont_want"><strong>Disclaimer</strong> : It is important that you mention all your requests in one go. We will try our best to accommodate all your requests. Once the portfolio is allocated, any further changes will not be possible. Important to note that if the final portfolio allocated post changes is materially different from our model portfolio, the performance between the two may vary.</p>
  </div>

</ng-container>    

      <div class="text-center mt-4">
        <button mat-raised-button color="primary" type="submit" >Continue For The Agreement</button>
      </div>
      </form>
   

      <!-- <div *ngIf="onboard_category=='dwn_renewal'" class="text-center mt-4">
        <p><img src="./assets/images/investment-details_img.jpg" style=" max-width: 250px; display: block; margin: 30px auto; "></p>

        <button mat-raised-button color="primary" (click)="ShowAttention('')">Continue For The Agreement</button>
      </div> -->
      
  </div>
</section>


 
 



 